import { City, User } from 'app/typings';
import { TOP_GLOBAL_COUNTRY_CODES } from 'app/shared/utils/countries';
import { useIsPageCitySpecific } from 'app/shared/utils/useIsPageCitySpecific';
import { useCityContext } from 'app/shared/context/City';

export function useInferredUserCountryCode({
  city,
  user,
}: { city?: City; user?: User | null } = {}) {
  const { lastViewedCity, closestCities } = useCityContext();
  const { isPageCitySpecific } = useIsPageCitySpecific();

  const cityBeingViewedCurrently = isPageCitySpecific
    ? lastViewedCity
    : undefined;

  // NOTE: Do not refactor this, we need to check all the possible cities for
  // country?.countryCode in case it is missing from any city data
  const inferredUserCountryCodeUpperCase =
    user?.mobile?.countryCodeIso3166 ||
    user?.homeCity?.country?.countryCode ||
    city?.country?.countryCode ||
    cityBeingViewedCurrently?.country?.countryCode ||
    closestCities?.[0]?.country?.countryCode;

  const inferredUserCountryCode = inferredUserCountryCodeUpperCase?.toLowerCase();
  const inferredCountryIsTopGlobalCountry =
    !!inferredUserCountryCode &&
    TOP_GLOBAL_COUNTRY_CODES.includes(inferredUserCountryCode);

  return { inferredUserCountryCode, inferredCountryIsTopGlobalCountry };
}
